.about-comp {
  display: flex;
  flex-direction: column;
  @media (min-width: 601px) {
    gap: 64px;
    padding: 60px 64px;
    .about,
    .culture {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
    .detail {
      flex: 1;
      margin: 0 32px;
    }
    .title-detail {
      color: #f8f8f8;
      font-size: 32px;
      font-weight: 900;
      margin-bottom: 20px;
    }
    .info-detail {
      color: #94a7b7;
      text-align: justify;
      letter-spacing: 1px;
      line-height: 1.5;
    }
    .detail-img {
      flex: 1;
      padding: 32px;
    }
    .img-detail {
      max-height: 316px;
      width: 100%;
    }
    .logo-culture {
      margin-top: 57px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 32px;
      img {
        max-height: 179px;
      }
    }
    .about-mobile,
    .culture-mobile {
      display: none;
    }
  }
  @media (max-width: 600px) {
    gap: 32px;
    padding: 60px 0;
    .about,
    .culture {
      display: none;
    }
    .about-mobile,
    .culture-mobile {
      display: block;
    }
    .detail-mobile {
      padding: 0 24px;
      .img-detail {
        max-height: 208px;
        width: 100%;
        margin-bottom: 16px;
      }
      .info-detail {
        color: #94a7b7;
        text-align: justify;
        letter-spacing: 1px;
        line-height: 1.5;
        font-size: 14px;
      }
      .title-detail {
        color: #f8f8f8;
        font-size: 32px;
        font-weight: 900;
        margin-bottom: 16px;
      }
    }
    .logo-culture {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      .img-culture {
        display: flex;
        gap: 32px;
        max-width: 230px;
        width: 100%;
      }
      img {
        max-height: 165px;
        max-width: 99px;
        width: 100%;
      }
    }
  }
}
