@media (min-width: 601px) {
  .contact-comp {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 0;
    .title {
      color: #fff;
      font-size: 64px;
      margin-bottom: 40px;
    }
    .form-register {
      max-width: 800px;
      width: 100%;
      margin-bottom: 32px;
      input,
      textarea {
        width: 100%;
        outline: none;
        font-size: 14px;
        color: #000;
        padding: 14.5px 7px;
        border-radius: 2px;
        border: none;
        margin-bottom: 8px;
      }
      .sub-btn {
        padding: 19px 32px;
        font-size: 14px;
      }
    }
    .term {
      color: #9aacbc;
      font-size: 12px;
      .term-action {
        display: flex;
        justify-content: center;
        width: 100%;
        max-width: 800px;
      }
      .weight-text {
        font-weight: 700;
        color: #fff;
      }
      .term-info {
        margin-top: 16px;
        display: flex;
        justify-content: center;
        width: 100%;
        max-width: 800px;
        font-size: 16px;
      }
    }
  }
}
@media (max-width: 600px) {
  .contact-comp {
    display: none;
  }
}
