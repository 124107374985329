@import "~react-image-gallery/styles/scss/image-gallery.scss";

.project-comp {
  padding: 60px 0;
  .title {
    font-size: 64px;
    font-weight: 900;
    color: #fff;
    margin-bottom: 40px;
  }

  @media (min-width: 601px) {
    .image-gallery-slide {
      left: 20%;
      right: 20%;
      width: 60%;
      padding: 0 20px;
    }
  }

  @media (max-width: 600px) {
    .image-gallery-slide {
      left: 10%;
      right: 10%;
      width: 80%;
      padding: 0 10px;
    }
  }

  .image-gallery-slide .image-gallery-image {
    max-width: 800px;
    max-height: 600px;
  }

  .image-gallery-thumbnails {
    padding: 32px 0;
  }
}
