.service-comp {
  display: flex;
  align-items: center;
  @media (min-width: 601px) {
    padding: 60px 64px;
    .service {
      display: flex;
      align-items: center;
    }
    img {
      max-height: 366px;
      width: 100%;
    }
    .detail {
      flex: 1;
      margin: 0 32px;
    }
    .title-detail {
      color: #f8f8f8;
      font-size: 32px;
      font-weight: 900;
      margin-bottom: 20px;
    }
    .info-detail {
      color: #94a7b7;
      text-align: justify;
      letter-spacing: 1px;
      line-height: 1.5;
    }
    .detail-img {
      flex: 1;
      padding: 32px;
    }
    .img-detail {
      max-height: 316px;
      width: 100%;
    }
    .service-mobile {
      display: none;
    }
  }
  @media (max-width: 600px) {
    padding: 60px 0;
    .service {
      display: none;
    }
    .service-mobile {
      display: block;
      .title-detail {
        color: #f8f8f8;
        font-size: 32px;
        font-weight: 900;
        margin-bottom: 32px;
      }
      img {
        max-height: 240px;
        width: 100%;
      }
      .info-detail {
        color: #94a7b7;
        text-align: justify;
        letter-spacing: 1px;
        line-height: 1.5;
        font-size: 14px;
        margin-top: 24px;
      }
    }

    .detail {
      flex: 1;
      margin: 0 32px;
    }

    .detail-img {
      flex: 1;
      padding: 32px;
    }
  }
}
