.newday-box {
  position: relative;
  .navbar-box {
    background-color: #000;
    width: 100%;
    max-height: 84px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10;
  }
  .home-box {
    width: 100%;
    max-height: 741px;
  }
  .about-box {
    width: 100%;
    background-color: #000;
  }
  .service-box {
    width: 100%;
    background-color: #000;
  }
  .project-box {
    width: 100%;
  }
  background-color: #000;
}
