body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Montserrat", sans-serif;
  src: local("Montserrat"),
    url("./assets/font/Montserrat-VariableFont_wght.ttf") format ("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "Montserrat", sans-serif;
  src: local("Montserrat"),
    url("./assets/font/Montserrat-VariableFont_wght.ttf") format ("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Archivo Black", sans-serif;
  src: local("Archivo Black"),
    url("./assets/font/ArchivoBlack-Regular.ttf") format ("truetype");
  font-weight: bold;
}
