.home-comp {
  background-image: url("../../../assets/image/bg-img1.svg");
  background-repeat: no-repeat;
  background-size: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: 601px) {
    min-height: 741px;
    .logo-home {
      max-width: 552px;
      width: 100%;
    }

    .title-home {
      color: #fff;
      font-size: 54px;
      font-weight: 900;
      display: flex;
      justify-content: center;
      p {
        margin: 20px 0;
      }
    }
  }
  @media (max-width: 600px) {
    min-height: 560px;
    .logo-home {
      max-width: 334px;
      width: 100%;
    }
    .title-home {
      color: #fff;
      font-size: 24px;
      font-weight: 900;
      display: flex;
      justify-content: center;
      p {
        margin: 12px 0;
      }
    }
  }
}
