.footer-comp {
  padding: 60px 0;
  .footer-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .fb-icon {
    width: 40px;
    height: 40px;
  }
  h3 {
    font-size: 16px;
    color: #f8f8f8;
    font-weight: 600;
  }
  .f8-color {
    color: #f8f8f8;
  }
  .white-color {
    color: #fff;
  }
  .text-16 {
    font-size: 16px;
  }
  .text-14 {
    font-size: 14px;
  }
  @media (min-width: 601px) {
    .footer-item {
      gap: 12px;
    }
  }
  @media (max-width: 600px) {
    .footer-item {
      gap: 16px;
      h3, p{
        font-size: 12px;
      }
    }
  }
}
