.nav-comp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 600px) {
    padding: 0 16px;
    .list-option {
      display: none;
    }
    .icon-menu {
      display: block;
      width: 40px;
      height: 40px;
      padding: 16px 0;
      position: relative;
      .list-option-mobile {
        position: absolute;
        right: 0;
        top: 52px;
        width: 253px;
        background-color: #000;
        z-index: 20;
        padding: 24px 8px 12px 24px;
        text-align: left;
        animation: fadeInRight ease-in 0.2s;
        -webkit-animation: fadeInRight ease-in 0.2s;
        a {
          color: #f8f8f8;
          font-size: 16px;
          text-decoration: none;
        }
        p {
          margin-bottom: 12px;
        }
        .separate {
          width: 100%;
          height: 1px;
          border-bottom: 1px solid #fff;
        }
      }
      .logo-nav {
        max-width: 49px;
        img {
          width: 100%;
        }
      }
    }
  }
  @media (min-width: 601px) {
    padding: 0 64px;
    .list-option {
      display: flex;
      align-items: center;
      gap: 46px;
      a {
        color: #f8f8f8;
        font-size: 16px;
        text-decoration: none;
      }
    }
    .logo-nav {
      max-height: 60px;
      margin: 12px 0;
      img {
        width: 100%;
      }
    }
    .icon-menu {
      display: none;
    }
  }
}
@keyframes fadeInRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
